<template>
  <div>
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;   position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="home-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white">
                  <h1>Chas Everitt - Luxury Winelands </h1>
                  <h2>Refined living in the heart of the Cape Winelands</h2>
                  <div class="search-filter">
                    <div class="row d-flex justify-content-center mx-auto">
                      <div class="col-lg-2 align-self-center">
                        <div class="dropdown">
                          <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            BUY
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-3 align-self-center">
                        <div class="dropdown">
                          <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            RESIDENTIAL
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-4 align-self-center">
                        <input type="text" placeholder="TYPE AREA OR SUBURB" class="w-100">
                      </div>
                      <div class="col-lg-2">
                        <a href="" class="btn cta-btn search-btn">search</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionOne mt-5">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-md-2"></div>
          <div class="col-md-3 h-100">
            <div class="switch-bg-lgrey h-100 p-5">
              <h2 class="switch-blue"><strong>Welcome to the Winelands</strong></h2>
              <p>Travel through vineyard-sprinkled valleys, towns rich in history and tree-lined avenues, to find some of
                the most sought-after real estate in South Africa. The Cape Winelands puts on an impressive display of
                beautiful scenery, paired with excellent food and wine, endless healthy lifestyle experiences, access to
                quality education institutions and prestigious addresses.</p>
              <p>As a destination synonymous with luxury, spacious surroundings and a high standard of living, it’s little
                wonder that so many people aspire to call the Winelands “home”.</p>
              <a href="#" class="btn cta-btn">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionTwo mt-5">
      <div class="container-fluid g-0">
        <div class="row g-0 switch-bg-lgrey">
          <div class="col-lg-6">
            <div class="estate-bg h-100"></div>
          </div>
          <div class="col-md-5 switch-bg-lgrey p-5 align-self-center">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>Luxury Estates</strong></h2>
              <p>Set in idyllic locations with wide-open spaces and tranquil environments, these luxury estates offer the
                perfect balance between fine living and a secure environment in which to raise a family or enjoy your
                retirement. </p>
              <p>Each uniquely designed estate complements the natural surroundings and provides you with the higher
                quality of life that you deserve. </p>
              <a href="#" class="btn cta-btn">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionThree mt-5">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-md-4"></div>
          <div class="col-md-3 h-100">
            <div class="switch-bg-lgrey h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>New Developments</strong></h2>
              <p>Few things excite us as much as breaking ground on a new development, where you find upmarket homes,
                state-of-the-art security, landscaped grounds, modern amenities, green technology and family-friendly
                facilities. </p>
              <p>These luxury new developments are architect-designed, with immaculate finishes, and enable discerning
                buyers and renters to live in their own piece of paradise. </p>
              <a href="#" class="btn cta-btn">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionFour mt-5">
      <div class="container-fluid g-0">
        <div class="row g-0 switch-bg-lgrey">
          <div class="col-1"></div>
          <div class="col-md-5 switch-bg-lgrey p-5 align-self-center">
            <div class="h-100 p-5 float-lg-end">
              <h2 class="switch-blue mb-4"><strong>Meet Our Team</strong></h2>
              <p>Say hello to the award-winning team that will expertly guide you on your property journey. </p>
              <p>With years of invaluable experience and the insights that come from living in the Winelands, this
                specialist team is passionate about providing each customer with the same exceptional service, so that
                your buying, selling, letting or renting experience is a seamless and memorable one.</p>
              <a href="#" class="btn cta-btn">Read more</a>
            </div>
          </div>
          <div class="col-lg-6 h-100">
            <div class="team-bg">
              <!-- <img src="images/meet-our-team.png" class="mw-100" /> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionFive my-5">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-12">
            <div class="text-center">
              <h2 class="switch-white mb-4">What our clients are saying</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div id="carouselExampleAutoplaying" class="carousel carousel-dark slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active"
              aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1"
              aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-white h-100">
                    <p class="quote">"</p>
                    <p class="switch-white">I've worked with Anneke on many projects for over 14 years. I've watched how
                      she deals with clients; her energy and passion for what she does, backed up by knowledge of the
                      product she is selling makes her the success that she is. Partnered with Amanda who complements
                      Anneke's energy with attention to detail and hard work, and a backup team with Aidan and Betro, make
                      Chas Everitt a formidable company to deal with.” </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-white h-100">
                    <p class="quote">"</p>
                    <p class="switch-white">I have had the pleasure of working with the Chas Everitt team for both home
                      rental and property purchase and it was a great experience. The team is hands-on, responsive and
                      goes above and beyond on every aspect of the process. They are my property go-to from now on. Keep
                      up the great work.” </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-white h-100">
                    <p class="quote">"</p>
                    <p class="switch-white">I didn’t think these agents would be any different to the average agents I’ve
                      dealt with in the past, however, I was really surprised with this company. Not only did we have a
                      really great experience from this hungry and professional team, but they have also helped friends of
                      mine sell their house in record time. Highly recommend this agency.” </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-white h-100">
                    <p class="quote">"</p>
                    <p class="switch-white">Renata at Chas Everitt has been amazing! She is very professional and very
                      attentive. She genuinely cares about her client's needs and advises accordingly.” </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-white h-100">
                    <p class="quote">"</p>
                    <p class="switch-white">I have now bought three homes from them in the past year and have never
                      experienced more friendly professional service in all my life. I would not think of dealing with any
                      other company after dealing with them. Just unbelievable!” </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-white h-100">
                    <p class="quote">"</p>
                    <p class="switch-white">There are no other agents that come close to the professionalism, integrity
                      and performance of Amanda and Anneke. They are truly exceptional agents, matchmaking buyers with
                      homes that meet their needs in Pearl Valley and Val De Vie.” </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container pb-5">
        <div class="row">
          <div class="col-lg-4 align-self-center">
            <h2 class="switch-lblue">Our Partners</h2>
          </div>
          <div class="col-lg-2 align-self-center text-center"><img src="images/logos/leading-estate-companies.png"
              class="mw-100mb-4" width="150px" /></div>
          <div class="col-lg-2 align-self-center text-center"><img src="images/logos/luxury-portfolio.png" class="mw-100"
              width="100px" /></div>
          <div class="col-lg-2 align-self-center"><img src="images/logos/private-property.png" class="mw-100" width="" />
          </div>
          <div class="col-lg-2 align-self-center"><img src="images/logos/betterbond.png" class="mw-100" width="" /></div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>